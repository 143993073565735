<template>
  <ScannedListLayout
    :title="$t('parcelsScanned.title')"
    :onClose="cancelParcelsReceiveProcess"
  >
    <ScannedList
      :isEmpty="emptyItems"
      :emptyText="$t('parcelsScanned.no-parcels')"
      :counts="mappedCounts"
    >
      <ScannedListItem
        v-for="(item, index) in items"
        :key="index"
        :success="item.success"
        :scannedValue="item.scanned_value"
        :recipient="item.recipient"
        :status="parcelStatus(item)"
        :statusClass="item.parcel_state"
        :subStatus="item.scanned_at"
      >
        <template v-slot:center>
          <p
            v-if="item.packets_count && item.packets_count > 0"
            class="d-flex align-items-center"
          >
            <package-icon class="package-icon" />
            <strong>{{ item.packets_count }}</strong>
          </p>
        </template>
      </ScannedListItem>
    </ScannedList>

    <template v-slot:footer-buttons>
      <a
        class="btn btn-primary btn-white"
        @click.prevent="rescanMissingParcels"
        v-if="hasFailedScans"
        >{{ $t("parcelsScanned.rescan") }}
      </a>
    </template>
  </ScannedListLayout>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import PackageIcon from "vue-material-design-icons/ArchiveOutline.vue";
import { getParams } from "@/mixins/platform-params.js";
import { MOBILE_TYPES } from "@/util/mobile.type";
import { SCANNED_COUNTS_TYPES, getScannedCounts } from "@/util/scanned-counts";
import ScannedList from "@/components/ScannedList/List";
import ScannedListItem from "@/components/ScannedList/Item";
import ScannedListLayout from "@/components/ScannedList/Layout";

export default {
  name: "ParcelsReceiveProcess",
  mixins: [getParams],
  components: {
    PackageIcon,
    ScannedList,
    ScannedListItem,
    ScannedListLayout
  },
  data() {
    return {
      batchId: this.$route.params.batchId
    };
  },

  beforeRouteLeave(to, from, next) {
    this.clearErrors();
    next();
  },

  async mounted() {
    await this.getScannedParcels(this.batchId);
  },

  computed: {
    ...mapState("scanned", ["items", "counts"]),
    ...mapGetters("scanned", ["emptyItems", "hasFailedScans"]),

    mappedCounts() {
      return getScannedCounts(SCANNED_COUNTS_TYPES.PARCELS, this.counts);
    }
  },

  methods: {
    ...mapActions("scanned", ["getScannedParcels", "confirmScannedParcels"]),
    ...mapMutations(["clearErrors"]),

    parcelStatus(parcel) {
      return (
        parcel.human_parcel_state ||
        parcel.human_result ||
        parcel.failure_reason
      );
    },

    rescanMissingParcels() {
      if (!this.isMobile) return;

      if (this.platform == MOBILE_TYPES.ANDROID) {
        window.PacketaPPA.backToScan(false);
      } else if (this.platform == MOBILE_TYPES.IOS) {
        window.webkit.messageHandlers.backToScan.postMessage({});
      }
    },

    async cancelParcelsReceiveProcess() {
      await this.confirmScannedParcels(this.batchId);

      if (!this.isMobile) {
        this.$router.replace({
          name: "dashboard",
          query: {
            platform: this.platform,
            device: this.device
          }
        });

        return;
      }

      if (this.platform == MOBILE_TYPES.ANDROID) {
        window.PacketaPPA.backToScan(true);
      } else if (this.platform == MOBILE_TYPES.IOS) {
        window.webkit.messageHandlers.backToScan.postMessage({});
      }
    }
  }
};
</script>
